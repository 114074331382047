import React, { lazy, Suspense } from 'react';
import { Outlet, Navigate, useRoutes } from 'react-router-dom';

import DashboardLayout from 'src/layouts/dashboard';
import GuestMiddleware from '../middleware/guestMiddleware';
import AuthMiddleware from '../middleware/authMiddleware';

// Lazy-loaded components
export const IndexPage = lazy(() => import('src/pages/Overview'));
export const StorePage = lazy(() => import('src/pages/stores/stores.page'));
export const Users = lazy(() => import('src/pages/Users'));

export const Page404 = lazy(() => import('src/pages/page-not-found'));

// BUSINESSES PAGES
export const BusinessPage = lazy(() => import('src/pages/Businesses.page'));
export const CreateBusiness = lazy(() => import('src/pages/BusinessesCreate.page'));
export const DetailBusiness = lazy(() => import('src/pages/BusinessesDetail.page'));

// APP SETTINGS PAGES
export const AppSettings = lazy(() => import('src/pages/AppSettings.page'));
export const CreateAppSettings = lazy(() => import('src/pages/AppSettingsCreate.page'));

// ATTRIBUTES AND ATTRİBUTE-VALUES PAGES
export const Attributes = lazy(() => import('src/pages/Attributes.page'));
export const CreateAttributes = lazy(() => import('src/pages/AttributesCreate.page'));
export const AttributeValues = lazy(() => import('src/pages/AttributeValues'));

// STORES
export const StoresPage = lazy(() => import('src/pages/stores/stores.page'));
export const StoresStoreDetailsPage = lazy(() =>
  import('src/pages/stores/storesStoreDetails.page')
);
export const StoresStoreDetailsStoreUserDetailsPage = lazy(() =>
  import('src/pages/stores/storesStoreDetailsStoreUserDetails.page')
);
export const StoresStoreDetailStoreDocsCreatePage = lazy(() => 
  import('src/pages/stores/storesStoreDocsCreate.page')
);

// STORE USER ROLES
export const StoreUserRolesPage = lazy(() => import('src/pages/storeUserRoles.page'));
export const StoreUserRolesNewStoreUserRolePage = lazy(() =>
  import('src/pages/storeUserRolesNewStoreUserRole.page')
);
export const StoreUserRolesEditPermissionsPage = lazy(() =>
  import('src/pages/storeUserRolesEditPermissions.page')
);

// STORE USER PERMISSIONS
export const StoreUserPermissionsPage = lazy(() => import('src/pages/storeUserPermissions.page'));

// STORE USERS
export const StoreUsersPage = lazy(() => import('src/pages/storeUsers.page'));
export const CreateStoreUsers = lazy(() => import('src/pages/StoreUsersCreate.page'));

// STORE TYPES 
export const StoreTypes = lazy(() => import('src/pages/storeTypes/StoreTypes.page'))
export const StoreTypesEdit = lazy(() => import('src/pages/storeTypes/StoreTypesEdit.page'))

// PAYMENT SYSTEM OPTİON SETTİNGS PAGES
export const PaymentSystemOptionSettings = lazy(() =>
  import('src/pages/PaymentSystemOptionSettings.page')
);
export const CreatePaymentSystemOptionSettings = lazy(() =>
  import('src/pages/PaymentSystemOptionSettingsCreate.page')
);
export const DetailPaymentSystemOptionSettings = lazy(() =>
  import('src/pages/PaymentSystemOptionSettingsDetail.page')
);

// CATEGORIES
export const CategoriesPage = lazy(() => import('src/pages/categories.page'));
export const CategoriesNewCategoryPage = lazy(() => import('src/pages/categoriesNewCategory.page'));

export const CategoryAttributes = lazy(() => import('src/pages/CategoryAttributes'));
export const CategoryDetail = lazy(() => import('src/pages/CategoryDetail'));

export const Products = lazy(() => import('src/pages/products'));
export const ProductDetails = lazy(() => import('src/pages/ProductDetail'));
export const CreateProduct = lazy(() => import('src/pages/CreateProduct'));

export const ProductCategories = lazy(() => import('src/pages/ProductCategories'));
export const LoginPage = lazy(() => import('src/pages/login'));
export const PaymentProcedures = lazy(() => import('src/pages/PaymentProcedures'));

export const UpdateNote = lazy(() => import('src/pages/updateNotes/UpdateNote'));
export const UpdateNotesDetail = lazy(() => import('src/pages/updateNotes/UpdateNotesDetail'));
export const UpdateNotesCreate = lazy(() => import('src/pages/updateNotes/UpdateNotesCreate'));

export const CommissionManagement = lazy(() => import('src/pages/CommissionManagement'));
export const CommissionRate = lazy(() => import('src/pages/CommissionRate'));
export const Streams = lazy(() => import('src/pages/streams'));

export const DetailStreams = lazy(() => import('src/pages/detail-stream'));

// CITY AND DISTRICT PAGES
export const CountryPage = lazy(() => import('src/pages/Country.page'));
export const CreateCountry = lazy(() => import('src/pages/CountryCreate.page'));
export const City = lazy(() => import('src/pages/City.page'));
export const District = lazy(() => import('src/pages/District.page'));

// STOCK MANAGEMENT PAGES
export const StockManagement = lazy(() => import('src/pages/stockManagement/StockManagement.page'));
export const StockManagementCreate = lazy(() => import('src/pages/stockManagement/StockManagementCreate.page'));
export const StockManagementDetail = lazy(() => import('src/pages/stockManagement/StockManagementDetail.page'));

// ORDERS PAGES
export const Orders = lazy(() => import('src/pages/orders/Orders.page'));
export const OrdersDetail = lazy(() => import('src/pages/orders/OrdersDetail.page'));
export const OrdersCanceled = lazy(() => import('src/pages/orders/OrdersCanceled.page'));

// COURIER PAGES
export const Courier = lazy(() => import('src/pages/courier/Courier.page'));
export const CourierApplications = lazy(() => import('src/pages/courier/CourierApplications.page'));
export const CourierDetail= lazy(() => import('src/pages/courier/CourierDetail.page'));

// Contact Pages
export const Contact = lazy(() => import('src/pages/contact/Contact.page'));
export const ContactDetail = lazy(() => import('src/pages/contact/ContactDetail.page'));

// Job Applications Pages
export const JobApplications = lazy(() => import('src/pages/jobAplication/JobApplications.page'));
export const JobApplicationsDetail = lazy(() => import('src/pages/jobAplication/JobApplicationDetail.page'));
// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      element: (
        <DashboardLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      ),
      children: [
        {
          element: (
            <AuthMiddleware>
              <IndexPage />
            </AuthMiddleware>
          ),
          index: true,
        },
        // {
        //   path: 'paymentProcedures',
        //   element: (
        //     <AuthMiddleware>
        //       <PaymentProcedures />
        //     </AuthMiddleware>
        //   ),
        // },
        // BUSINESS ROUTES
        // {
        //   path: 'businesses',
        //   element: (
        //     <AuthMiddleware>
        //       <BusinessPage />
        //     </AuthMiddleware>
        //   ),
        // },
        // {
        //   path: 'businesses/create',
        //   element: (
        //     <AuthMiddleware>
        //       <CreateBusiness />
        //     </AuthMiddleware>
        //   ),
        // },
        // {
        //   path: 'businesses/:id',
        //   element: (
        //     <AuthMiddleware>
        //       <DetailBusiness />
        //     </AuthMiddleware>
        //   ),
        // },
        // PAYMENT SYSTEM OPTİON SETTİNGS ROUTES
        {
          path: '/payment-system-option-settings',
          element: (
            <AuthMiddleware>
              <PaymentSystemOptionSettings />
            </AuthMiddleware>
          ),
        },
        {
          path: '/payment-system-option-settings/create',
          element: (
            <AuthMiddleware>
              <CreatePaymentSystemOptionSettings />
            </AuthMiddleware>
          ),
        },
        {
          path: '/payment-system-option-settings/:id',
          element: (
            <AuthMiddleware>
              <DetailPaymentSystemOptionSettings />
            </AuthMiddleware>
          ),
        },
        {
          path: 'payment/settings',
          element: (
            <AuthMiddleware>
              <PaymentSystemOptionSettings />
            </AuthMiddleware>
          ),
        },
        {
          path: 'users',
          element: (
            <AuthMiddleware>
              <Users />
            </AuthMiddleware>
          ),
        },
        {
          path: 'store-users/create',
          element: (
            <AuthMiddleware>
              <CreateStoreUsers />
            </AuthMiddleware>
          ),
        },
        // {
        //   path: 'detail',
        //   element: (
        //     <AuthMiddleware>
        //       <DetailPage />
        //     </AuthMiddleware>
        //   ),
        // },
        {
          path: 'detail/streams/:id',
          element: (
            <AuthMiddleware>
              <DetailStreams />
            </AuthMiddleware>
          ),
        },
        // STORES
        {
          path: '/stores',
          element: (
            <AuthMiddleware>
              <StoresPage />
            </AuthMiddleware>
          ),
        },
        {
          path: '/stores/new-store',
          element: (
            <AuthMiddleware>
              <StoresStoreDetailsPage />
            </AuthMiddleware>
          ),
        },
        {
          path: '/stores/store-details/:storeId',
          element: (
            <AuthMiddleware>
              <StoresStoreDetailsPage />
            </AuthMiddleware>
          ),
        },
        {
          path: '/stores/store-details/:storeId/new-store-user',
          element: (
            <AuthMiddleware>
              <StoresStoreDetailsStoreUserDetailsPage />
            </AuthMiddleware>
          ),
        },
        {
          path: '/stores/store-details/:storeId/store-user-details/:userId',
          element: (
            <AuthMiddleware>
              <StoresStoreDetailsStoreUserDetailsPage />
            </AuthMiddleware>
          ),
        },
        {
          path: '/stores/store-details/:storeId/new-store-docs',
          element: (
            <AuthMiddleware>
              <StoresStoreDetailStoreDocsCreatePage />
            </AuthMiddleware>
          ),
        },
        // STORE USER ROLES
        {
          path: '/store-user-roles',
          element: (
            <AuthMiddleware>
              <StoreUserRolesPage />
            </AuthMiddleware>
          ),
        },
        {
          path: '/store-user-roles/new-store-user-role',
          element: (
            <AuthMiddleware>
              <StoreUserRolesNewStoreUserRolePage />
            </AuthMiddleware>
          ),
        },
        {
          path: '/store-user-roles/edit-permissions/:id',
          element: (
            <AuthMiddleware>
              <StoreUserRolesEditPermissionsPage />
            </AuthMiddleware>
          ),
        },
        // STORE USER PERMISSIONS
        {
          path: '/store-user-permissions',
          element: (
            <AuthMiddleware>
              <StoreUserPermissionsPage />
            </AuthMiddleware>
          ),
        },
        // STORE USERS
        {
          path: 'store-users',
          element: (
            <AuthMiddleware>
              <StoreUsersPage />
            </AuthMiddleware>
          ),
        },
        // Store TYPES
        {
          path: 'store-types',
          element: (
            <AuthMiddleware>
              <StoreTypes />
            </AuthMiddleware>
          ),
        },
        {
          path: 'store-types/:id',
          element: (
            <AuthMiddleware>
              <StoreTypesEdit />
            </AuthMiddleware>
          ),
        },
        {
          path: 'store-types/create',
          element: (
            <AuthMiddleware>
              <StoreTypesEdit />
            </AuthMiddleware>
          ),
        },
        // CATEGORIES
        {
          path: '/categories',
          element: (
            <AuthMiddleware>
              <CategoriesPage />
            </AuthMiddleware>
          ),
        },
        {
          path: '/categories/new-category',
          element: (
            <AuthMiddleware>
              <CategoriesNewCategoryPage />
            </AuthMiddleware>
          ),
        },
        // APP SETTINGS
        {
          path: 'app-settings',
          element: (
            <AuthMiddleware>
              <AppSettings />
            </AuthMiddleware>
          ),
        },
        {
          path: 'app-settings/create',
          element: (
            <AuthMiddleware>
              <CreateAppSettings />
            </AuthMiddleware>
          ),
        },
        //  ATTRIBUTES ROUTES
        {
          path: 'attributes',
          element: (
            <AuthMiddleware>
              <Attributes />
            </AuthMiddleware>
          ),
        },
        {
          path: 'attributes/create',
          element: (
            <AuthMiddleware>
              <CreateAttributes />
            </AuthMiddleware>
          ),
        },
        {
          path: 'attributeValues/:id',
          element: (
            <AuthMiddleware>
              <AttributeValues />
            </AuthMiddleware>
          ),
        },
        {
          path: 'categoryAttributes',
          element: (
            <AuthMiddleware>
              <CategoryAttributes />
            </AuthMiddleware>
          ),
        },
        {
          path: 'categories/detail/:id',
          element: (
            <AuthMiddleware>
              <CategoryDetail />
            </AuthMiddleware>
          ),
        },
        {
          path: 'products',
          element: (
            <AuthMiddleware>
              <Products />
            </AuthMiddleware>
          ),
        },
        {
          path: '/products/create',
          element: (
            <AuthMiddleware>
              <CreateProduct />
            </AuthMiddleware>
          ),
        },
        {
          path: 'productsDetail/:id',
          element: (
            <AuthMiddleware>
              <ProductDetails />
            </AuthMiddleware>
          ),
        },
        {
          path: 'productCategories',
          element: (
            <AuthMiddleware>
              <ProductCategories />
            </AuthMiddleware>
          ),
        },
        {
          path: 'update-notes',
          element: (
            <AuthMiddleware>
              <UpdateNote />
            </AuthMiddleware>
          ),
        },
        {
          path: 'update-notes/:id',
          element: (
            <AuthMiddleware>
              <UpdateNotesDetail />
            </AuthMiddleware>
          ),
        },
        {
          path: 'update-notes/create',
          element: (
            <AuthMiddleware>
              <UpdateNotesCreate />
            </AuthMiddleware>
          ),
        },
        {
          path: 'stats',
          element: (
            <AuthMiddleware>
              <IndexPage />
            </AuthMiddleware>
          ),
        },
        {
          path: 'commissionManagement',
          element: (
            <AuthMiddleware>
              <CommissionManagement />
            </AuthMiddleware>
          ),
        },
        {
          path: 'commissionRate',
          element: (
            <AuthMiddleware>
              <CommissionRate />
            </AuthMiddleware>
          ),
        },
        {
          path: 'streams',
          element: (
            <AuthMiddleware>
              <Streams />
            </AuthMiddleware>
          ),
        },
        // COUNTRY, CITY AND DISTRICT ROUTES
        {
          path: 'country',
          element: (
            <AuthMiddleware>
              <CountryPage />
            </AuthMiddleware>
          ),
        },
        {
          path: 'country/create',
          element: (
            <AuthMiddleware>
              <CreateCountry />
            </AuthMiddleware>
          ),
        },
        {
          path: 'city/:id',
          element: (
            <AuthMiddleware>
              <City />
            </AuthMiddleware>
          ),
        },
        {
          path: 'district/:id',
          element: (
            <AuthMiddleware>
              <District />
            </AuthMiddleware>
          ),
        },
        // STOCK MANAGEMENT ROUTES
        {
          path: 'stock-management',
          element: (
            <AuthMiddleware>
              <StockManagement />
            </AuthMiddleware>
          ),
        },
        {
          path: 'stock-management/create',
          element: (
            <AuthMiddleware>
              <StockManagementCreate />
            </AuthMiddleware>
          ),
        },
        {
          path: 'stock-management/:id',
          element: (
            <AuthMiddleware>
              <StockManagementDetail />
            </AuthMiddleware>
          ),
        },
        // ORDERS ROUTES
        {
          path: 'orders',
          element: (
            <AuthMiddleware>
              <Orders />
            </AuthMiddleware>
          ),
        },
        {
          path: 'orders/:id',
          element: (
            <AuthMiddleware>
              <OrdersDetail />
            </AuthMiddleware>
          ),
        },
        {
          path: 'orders/canceled',
          element: (
            <AuthMiddleware>
              <OrdersCanceled />
            </AuthMiddleware>
          ),
        },
        // COURIER ROUTES  
        {
          path: 'courier',
          element: (
            <AuthMiddleware>
              <Courier />
            </AuthMiddleware>
          ),
        },
        {
          path: 'courier-applications',
          element: (
            <AuthMiddleware>
              <CourierApplications />
            </AuthMiddleware>
          ),
        },  
        {
          path: 'courier/:id',
          element: (
            <AuthMiddleware>
              <CourierDetail />
            </AuthMiddleware>
          ),
        },  
        // Contact Routes
        {
          path: 'contact',
          element: (
            <AuthMiddleware>
              <Contact />
            </AuthMiddleware>
          ),
        },  
        {
          path: 'contact/:id',
          element: (
            <AuthMiddleware>
              <ContactDetail />
            </AuthMiddleware>
          ),
        },  
        // Job Applications
        {
          path: 'job-applications',
          element: (
            <AuthMiddleware>
              <JobApplications />
            </AuthMiddleware>
          ),
        },  
        {
          path: 'job-applications/:id',
          element: (
            <AuthMiddleware>
              <JobApplicationsDetail />
            </AuthMiddleware>
          ),
        },  
      ],
    },
    {
      path: 'login',
      element: (
        <GuestMiddleware>
          <LoginPage />
        </GuestMiddleware>
      ),
    },
    {
      path: '404',
      element: <Page404 />,
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
